// taken from Gitlab
// https://gitlab.com/gitlab-org/gitlab/-/blob/master/app/assets/javascripts/lib/utils/url_utility.js

const PATH_SEPARATOR = '/';
const PATH_SEPARATOR_LEADING_REGEX = new RegExp(`^${PATH_SEPARATOR}+`);
const PATH_SEPARATOR_ENDING_REGEX = new RegExp(`${PATH_SEPARATOR}+$`);

export function cleanLeadingSeparator(path) {
    return path.replace(PATH_SEPARATOR_LEADING_REGEX, '');
}

function cleanEndingSeparator(path) {
    return path.replace(PATH_SEPARATOR_ENDING_REGEX, '');
}

/**
 * Safely joins the given paths which might both start and end with a `/`
 *
 * Example:
 * - `joinPaths('abc/', '/def') === 'abc/def'`
 * - `joinPaths(null, 'abc/def', 'zoo) === 'abc/def/zoo'`
 *
 * @param  {...String} paths
 * @returns {String}
 */
export function joinPaths(...paths) {
    return paths.reduce((acc, path) => {
        if (!path) {
            return acc;
        }
        if (!acc) {
            return path;
        }

        return [cleanEndingSeparator(acc), PATH_SEPARATOR, cleanLeadingSeparator(path)].join('');
    }, '');
}
