<template>
    <div class="schedule">
        <h2>{{ busLabel }} Bus</h2>
        <ul>
            <bus-arrival v-for="(arrival, index) in filteredArrivals"
                         :key="index"
                         :route="arrival.routeID"
                         :minutes="arrival.minutes"
                         :arriving-soon-minutes="arrivingSoonMinutes"></bus-arrival>
        </ul>
    </div>
</template>

<script>
    import BusArrival from './BusArrival.vue';
    import dayjs from 'dayjs';
    import customParseFormat from 'dayjs/plugin/customParseFormat';
    dayjs.extend(customParseFormat);

    export default {
        name: 'BusArrivalList',
        components: {
            BusArrival
        },
        props: {
            arrivals: {
                type: Array,
                required: true
            },
            perList: {
                type: Number,
                required: true
            },
            arrivingSoonMinutes: {
                type: Number,
                required: true
            },
            busLabel: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                currentTime: new Date(),
                timeRefreshInterval: 15     // in seconds
            }
        },
        computed: {
            filteredArrivals() {
                const vm = this;
                const now = dayjs(vm.currentTime);
                return this.arrivals
                    .map(arrival => {
                        const arrivalTime = dayjs(arrival.arrivalTime, "H:mmA")
                        return {
                            routeID: arrival.routeID,
                            minutes: arrivalTime.diff(now, 'minute'),
                            arrivalTime: arrival.arrivalTime
                        }
                    })
                    .slice(0, vm.perList)
                    .filter(arrival => arrival.minutes >= 0);
            }
        },
        created() {
            setInterval(() => {
                this.currentTime = new Date();
            }, this.timeRefreshInterval * 1000);
        }
    }
</script>