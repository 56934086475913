import axios from 'axios';

export const defaultConfiguration = {
    slidesDuration: 12,        // time in seconds
    sidebarLocation: 'none',
    slidesTransition: 'cross-dissolve',
    slidesRefreshTime: 30,      // time in minutes
    slidesOnWordpress: true,
    showClock: false,
    busScheduleRefreshTime: 120,
    busArrivalsPerList: 3,
    busArrivingSoon: 5,         // time in minutes
    showWeather: false,
    weatherRefresh: 15,          // time in minutes
    showLogo: false,
    showBusSchedule: false,
    busScheduleLabel: 'Bus Schedule',
    slidesShowTitle: false,
    slidesShowExcerpt: false,
    configRefreshTime: 60       //time in minutes
}

/**
 * Returns a merged set of configuration options from all available sources
 * */
export const loadConfig = () => {
    // possibly add other configuration options - order them with a particular priority (customizer comes last to override local json, etc)

    return new Promise((resolve, reject) => {
        var now = new Date().getTime();   // cache-busting
        const localConfigPromise = axios.get('config.json?v=' + now)
            .then(response => response.data);

        // additional configuration options - only an example that is returned with a Promise
        const anotherConfigPromise = new Promise((resolve) => resolve({  }));

        // additional configuration options - only an example that is returned with a Promise
        const aThirdConfigPromise = new Promise((resolve) => resolve({  }));

        // apply configurations in order (overriding from top to bottom)
        Promise.all([localConfigPromise, anotherConfigPromise, aThirdConfigPromise])
            .then((configOptions) => {
                // how multiple configurations are applied, in order from top to bottom
                const finalConfig = { ...defaultConfiguration, ...configOptions[0], ...configOptions[1], ...configOptions[2] };

                resolve(finalConfig);
            })
            .catch(() => {
                reject();
            })
    });
}

// pulled from Vuex mapState
// https://github.com/vuejs/vuex/blob/main/src/helpers.js

export const mapOptions = normalizeNamespace((namespace, states) => {
    const res = {}
    normalizeMap(states).forEach(({ key, val }) => {
        res[key] = function mappedState() {
            return this.$config[val]
        }
    })
    return res
})

/**
 * Reloads all configuration options from all available sources
 * @returns Promise
 * */
export const reloadConfig = (existingConfig) => {
    return loadConfig()
        .then(config => {
            Object.keys(config).forEach(option => {
                existingConfig[option] = config[option];
            });
        });
}

/**
 * Normalize the map
 * normalizeMap([1, 2, 3]) => [ { key: 1, val: 1 }, { key: 2, val: 2 }, { key: 3, val: 3 } ]
 * normalizeMap({a: 1, b: 2, c: 3}) => [ { key: 'a', val: 1 }, { key: 'b', val: 2 }, { key: 'c', val: 3 } ]
 * @param {Array|Object} map
 * @return {Object}
 */
function normalizeMap(map) {
    if (!isValidMap(map)) {
        return []
    }
    return Array.isArray(map)
        ? map.map(key => ({ key, val: key }))
        : Object.keys(map).map(key => ({ key, val: map[key] }))
}

/**
 * Return a function expect two param contains namespace and map. it will normalize the namespace and then the param's function will handle the new namespace and the map.
 * @param {Function} fn
 * @return {Function}
 */
function normalizeNamespace(fn) {
    return (namespace, map) => {
        if (typeof namespace !== 'string') {
            map = namespace
            namespace = ''
        } else if (namespace.charAt(namespace.length - 1) !== '/') {
            namespace += '/'
        }
        return fn(namespace, map)
    }
}

/**
 * Validate whether given map is valid or not
 * @param {*} map
 * @return {Boolean}
 */
function isValidMap(map) {
    return Array.isArray(map) || isObject(map)
}

function isObject(obj) {
    return obj !== null && typeof obj === 'object'
}