import { createApp, h, reactive } from "vue";
import App from "./App.vue";
import MadisonMetroService from '@/services/MadisonMetroService';
import OpenWeatherMapService from '@/services/OpenWeatherMapService';
import { loadConfig } from '@/config';

loadConfig()
    .then((config) => {

        const app = createApp({
            provide: {
                metroService: new MadisonMetroService(config.madisonMetroApiKey),
                weatherService: new OpenWeatherMapService(config.openWeatherMapApiKey)
            },
            render: () => h(App)
        });

        app.config.globalProperties.$config = reactive(config)

        app.mount("#app")
    })

