<template>
    <div class="clock" :class="lightdark" v-if="ampm != ''">
        <div class="clock__hours">
            <span v-text="hours"></span>:
        </div>
        <div class="clock__minutes" v-text="minutes"></div>
        <span class="clock__ampm" v-text="ampm"></span>
    </div>
</template>

<script>
    import dayjs from 'dayjs';

    export default {
        name: 'DigitalClock',
        data() {
            return {
                hours: '',
                minutes: '',
                seconds: '',
                ampm: ''
            };
        },
        props: ["lightdark"],
        mounted() {
            this.$options.interval = setInterval(this.updateDateTime, 1000);
        },
        beforeUnmount() {
            clearInterval(this.$options.interval);
        },
        methods: {
            updateDateTime() {
                const now = dayjs();
                this.hours = now.format('h');
                this.minutes = now.format('mm');
                this.seconds = now.format('ss');
                this.ampm = now.format('A');
            }
        }
    };
</script>

<style scoped>
    .clock {
        border-radius: 0.5rem;
        display: inline-block;
        margin-bottom: 0.5em;
        /*position: absolute;
    bottom: 15px;
    left: 0px;
    right: 15px;
    z-index: 3;*/
    }

        .clock.light .clock__hours,
        .clock.light .clock__minutes,
        .clock.light .clock__ampm,
        .clock.light .clock__seconds {
            color: rgba(255, 255, 255, 0.7);
        }

    .clock__hours,
    .clock__minutes,
    .clock__ampm,
    .clock__seconds {
        display: inline-block;
        color: rgba(0, 0, 0, 0.7);
        font-size: 2.5rem;
        font-weight: 700;
        padding: 0.5rem 0.1rem;
        text-align: left;
        position: relative;
        transition: all 1s;
    }

    .clock__hours {
        border-radius: 0.5rem 0 0 0.5rem;
    }

    .clock__minutes {
    }

    .clock__seconds {
        border-radius: 0 0.5rem 0.5rem 0;
    }

    .clock__ampm {
        font-size: 1rem;
    }
</style>
