import axios from 'axios';

export default class OpenWeatherMapService {
    constructor(apiKey = '', endpoint = '') {
        const defaultEndpoint = 'https://api.openweathermap.org/data/2.5/onecall';

        this.endpoint = (endpoint == '') ? defaultEndpoint : endpoint;
        this.apiKey = apiKey;

    }

    /**
     * Retrieve weather data from OpenWeatherMap
     * @param {String} latitude Latitude of location in decimal format
     * @param {String} longitude Longitude of location in decimal format
     * @param {Array.<String>} exclude What to exclude from return data.  Minutely and hourly updates by default.  Valid values are current, minutely, hourly, daily, alerts
     */
    getWeatherData(latitude, longitude, exclude = ['minutely', 'hourly']) {

        const url = this.endpoint;
        return axios({
            url,
            params: {
                lat: latitude,
                lon: longitude,
                exclude: exclude.join(','),
                units: "imperial",
                appid: this.apiKey
            }
        });
    }

   
}
