<template>
    <div class="weather" v-if="dataReady">
        <div class="icon sun-shower" :class="{ showIcon: isSunShower }">
            <div class="cloud"></div>
            <div class="sun">
                <div class="rays"></div>
            </div>
            <div class="rain"></div>
        </div>

        <div class="icon thunder-storm" :class="{ showIcon: isStorm }">
            <div class="cloud"></div>
            <div class="lightning">
                <div class="bolt"></div>
                <div class="bolt"></div>
            </div>
        </div>

        <div class="icon cloudy" :class="{ showIcon: isClouds }">
            <div class="cloud"></div>
            <div class="cloud"></div>
        </div>

        <div class="icon flurries" :class="{ showIcon: isSnow }">
            <div class="cloud"></div>
            <div class="snow">
                <div class="flake"></div>
                <div class="flake"></div>
            </div>
        </div>

        <div class="icon sunny" :class="{ showIcon: isSunny }">
            <div class="sun">
                <div class="rays"></div>
            </div>
        </div>

        <div class="icon rainy" :class="{ showIcon: isRain }">
            <div class="cloud"></div>
            <div class="rain"></div>
        </div>


        <div class="temp">{{ temperateAsInt }} <span>&#176;</span></div>
        <div class="condition">{{ weatherData.current.weather[0].main }}</div>

    </div>
</template>

<script>
    export default {
        name: "WeatherDisplay",
        props: {
            refreshTime: {
                type: Number,
                required: true
            },
            latitude: {
                type: Number,
                required: true
            },
            longitude: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                weatherData: {},
                errors: [],
                lastDataLoad: 0,
                noteworthy: false,
                mention: false,
                dataReady: false,
            };
        },
        computed: {
            // conditions from OpenWeatherMap
            // https://openweathermap.org/weather-conditions#Weather-Condition-Codes-2
            conditionCode() {
                return this.weatherData.current.weather[0].id.toString();
            },
            temperateAsInt() {
                return parseInt(this.weatherData.current.temp);
            },
            isRain() {
                return this.conditionCode.substring(0, 1) == '5';
            },
            isClouds() {
                return this.conditionCode.substring(0, 1) == '7' || (this.conditionCode.substring(0, 2) == '80' && this.conditionCode != '800');
            },
            isSunny() {
                return this.conditionCode == '800'
            },
            isStorm() {
                return this.conditionCode.substring(0, 1) == '2';
            },

            isSunShower() {
                return this.conditionCode.substring(0, 1) == '3';
            },
            isSnow() {
                return this.conditionCode.substring(0, 1) == '6';
            },
            refreshTimeInMs() {
                return this.refreshTime * 60 * 1000;
            }
        },
        created: function () {
            this.weatherRefresh(this.refreshTimeInMs);

        },
        methods: {
            /**
             * Loads the weather data from the API
             */
            loadWeatherData() {
                const now = new Date().getTime();

                this.loadDataFromCache();

                if (now < this.expires) {
                    this.dataReady = true
                    return;
                }
                    

                this.weatherService.getWeatherData(
                    this.latitude,
                    this.longitude
                )
                    .then(response => {
                        this.weatherData = response.data;
                        this.dataReady = true;
                        this.expires = now + this.refreshTimeInMs

                        this.storeInCache(this.cacheKey('data'), this.weatherData);
                        this.storeInCache(this.cacheKey('expire'), this.expires);
                    })
            },

            /**
             * Populates local data with cached data
             * */
            loadDataFromCache() {
                this.weatherData = this.retrieveFromCache(this.cacheKey('data'))
                this.expires = parseInt(this.retrieveFromCache(this.cacheKey('expire')));
            },

            /**
             * Load weather data and set to automatically refresh
             * @param {Number} refreshInSeconds refresh time in seconds
             * */
            weatherRefresh(refreshInSeconds) {
                const vm = this;
                vm.loadWeatherData(vm.weatherConfiguration);

                //Update weather every X milliseconds
                setInterval(function () {
                    vm.loadWeatherData(vm.weatherConfiguration);
                }, refreshInSeconds * 1000);
            },

            /**
             * Key for caching a piece of data
             * @param {string} key
             */
            cacheKey(key) {
                return `weather-${key}`;
            },
            /**
             * Retrieves data from cache
             * @param key
             */
            retrieveFromCache(key) {
                const data = localStorage.getItem(key);
                if (data)
                    return JSON.parse(data);

                return '';
            },
            /**
             * Store data in cache
             * @param key
             * @param data
             */
            storeInCache(key, data) {
                localStorage.setItem(key, JSON.stringify(data))
            }

        },
        inject: {
            weatherService: {
                type: Object
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .weather {
        position: relative;

        .temp {
            margin-top: 4rem;
            font-size: 8rem;
            font-weight: bold;
            display: inline-block;
            line-height: 1;

            span {
                font-family: "Red Hat Display","Verlag-Light";
                color: rgba(0, 0, 0, 0.5);
                font-size: 7rem;
                margin-left: -25px;
            }
        }

        .condition {
            font-size: 2.5rem;
        }
    }

    h1 {
        margin-bottom: 1.375em;
        color: #fff;
        font-weight: 100;
        font-size: 2em;
        text-transform: uppercase;
    }

    p,
    a {
        color: rgba(255, 255, 255, 0.3);
        font-size: small;
    }

    p {
        margin: 1.375rem 0;
    }

    .icon {
        position: absolute;
        top: -20px;
        right: -20px;
        display: none;
        width: 12em;
        margin: 0 auto;
        height: 10em;
        font-size: 1em; /* control icon size here */
    }

    .icon.showIcon {
        display: block;
    }

    .cloud {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        width: 3.6875em;
        height: 3.6875em;
        margin: -1.84375em;
        background: currentColor;
        border-radius: 50%;
        box-shadow: -2.1875em 0.6875em 0 -0.6875em, 2.0625em 0.9375em 0 -0.9375em, 0 0 0 0.375em #000, -2.1875em 0.6875em 0 -0.3125em #000, 2.0625em 0.9375em 0 -0.5625em #000;
    }

    .cloud:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: -0.5em;
        display: block;
        width: 4.5625em;
        height: 1em;
        background: currentColor;
        box-shadow: 0 0.4375em 0 -0.0625em #000;
    }

    .cloud:nth-child(2) {
        z-index: 0;
        background: #000;
        box-shadow: -2.1875em 0.6875em 0 -0.6875em #000, 2.0625em 0.9375em 0 -0.9375em #000, 0 0 0 0.375em #000, -2.1875em 0.6875em 0 -0.3125em #000, 2.0625em 0.9375em 0 -0.5625em #000;
        opacity: 0.3;
        transform: scale(0.5) translate(6em, -3em);
        animation: cloud 4s linear infinite;
    }

    .cloud:nth-child(2):after {
        background: #000;
    }

    .sun {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2.5em;
        height: 2.5em;
        margin: -1.25em;
        background: currentColor;
        border-radius: 50%;
        box-shadow: 0 0 0 0.375em #000;
        animation: spin 12s infinite linear;
    }

    .rays {
        position: absolute;
        top: -2em;
        left: 50%;
        display: block;
        width: 0.375em;
        height: 1.125em;
        margin-left: -0.1875em;
        background: #000;
        border-radius: 0.25em;
        box-shadow: 0 5.375em #000;
    }

    .rays:before,
    .rays:after {
        content: "";
        position: absolute;
        top: 0em;
        left: 0em;
        display: block;
        width: 0.375em;
        height: 1.125em;
        transform: rotate(60deg);
        transform-origin: 50% 3.25em;
        background: #000;
        border-radius: 0.25em;
        box-shadow: 0 5.375em #000;
    }

    .rays:before {
        transform: rotate(120deg);
    }

    .cloud + .sun {
        margin: -2em 1em;
    }

    .rain,
    .lightning,
    .snow {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        width: 3.75em;
        height: 3.75em;
        margin: 0.375em 0 0 -2em;
        background: transparent;
    }

    .rain:after {
        content: "";
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        width: 1.125em;
        height: 1.125em;
        margin: -1em 0 0 -0.25em;
        background: #0cf;
        border-radius: 100% 0 60% 50% / 60% 0 100% 50%;
        box-shadow: 0.625em 0.875em 0 -0.125em rgba(0, 0, 0, 0.2), -0.875em 1.125em 0 -0.125em rgba(0, 0, 0, 0.2), -1.375em -0.125em 0 rgba(0, 0, 0, 0.2);
        transform: rotate(-28deg);
        animation: rain 3s linear infinite;
    }

    .bolt {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -0.25em 0 0 -0.125em;
        color: #000;
        opacity: 0.3;
        animation: lightning 2s linear infinite;
    }

    .bolt:nth-child(2) {
        width: 0.5em;
        height: 0.25em;
        margin: -1.75em 0 0 -1.875em;
        transform: translate(2.5em, 2.25em);
        opacity: 0.2;
        animation: lightning 1.5s linear infinite;
    }

    .bolt:before,
    .bolt:after {
        content: "";
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        margin: -1.625em 0 0 -1.0125em;
        border-top: 1.25em solid transparent;
        border-right: 0.75em solid;
        border-bottom: 0.75em solid;
        border-left: 0.5em solid transparent;
        transform: skewX(-10deg);
    }

    .bolt:after {
        margin: -0.25em 0 0 -0.25em;
        border-top: 0.75em solid;
        border-right: 0.5em solid transparent;
        border-bottom: 1.25em solid transparent;
        border-left: 0.75em solid;
        transform: skewX(-10deg);
    }

    .bolt:nth-child(2):before {
        margin: -0.75em 0 0 -0.5em;
        border-top: 0.625em solid transparent;
        border-right: 0.375em solid;
        border-bottom: 0.375em solid;
        border-left: 0.25em solid transparent;
    }

    .bolt:nth-child(2):after {
        margin: -0.125em 0 0 -0.125em;
        border-top: 0.375em solid;
        border-right: 0.25em solid transparent;
        border-bottom: 0.625em solid transparent;
        border-left: 0.375em solid;
    }

    .flake:before,
    .flake:after {
        content: "\2744";
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -1.025em 0 0 -1.0125em;
        color: #000;
        list-height: 1em;
        opacity: 0.2;
        animation: spin 8s linear infinite reverse;
    }

    .flake:after {
        margin: 0.125em 0 0 -1em;
        font-size: 1.5em;
        opacity: 0.4;
        animation: spin 14s linear infinite;
    }

    .flake:nth-child(2):before {
        margin: -0.5em 0 0 0.25em;
        font-size: 1.25em;
        opacity: 0.2;
        animation: spin 10s linear infinite;
    }

    .flake:nth-child(2):after {
        margin: 0.375em 0 0 0.125em;
        font-size: 2em;
        opacity: 0.4;
        animation: spin 16s linear infinite reverse;
    }

    /* Animations */

    @keyframes spin {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes cloud {
        0% {
            opacity: 0;
        }

        50% {
            opacity: 0.3;
        }

        100% {
            opacity: 0;
            transform: scale(0.5) translate(-200%, -3em);
        }
    }

    @keyframes rain {
        0% {
            background: #0cf;
            box-shadow: 0.625em 0.875em 0 -0.125em rgba(255, 255, 255, 0.2), -0.875em 1.125em 0 -0.125em rgba(255, 255, 255, 0.2), -1.375em -0.125em 0 #0cf;
        }

        25% {
            box-shadow: 0.625em 0.875em 0 -0.125em rgba(255, 255, 255, 0.2), -0.875em 1.125em 0 -0.125em #0cf, -1.375em -0.125em 0 rgba(255, 255, 255, 0.2);
        }

        50% {
            background: rgba(255, 255, 255, 0.3);
            box-shadow: 0.625em 0.875em 0 -0.125em #0cf, -0.875em 1.125em 0 -0.125em rgba(255, 255, 255, 0.2), -1.375em -0.125em 0 rgba(255, 255, 255, 0.2);
        }

        100% {
            box-shadow: 0.625em 0.875em 0 -0.125em rgba(255, 255, 255, 0.2), -0.875em 1.125em 0 -0.125em rgba(255, 255, 255, 0.2), -1.375em -0.125em 0 #0cf;
        }
    }

    @keyframes lightning {
        45% {
            color: #fff;
            background: #fff;
            opacity: 0.2;
        }

        50% {
            color: #0cf;
            background: #0cf;
            opacity: 1;
        }

        55% {
            color: #fff;
            background: #fff;
            opacity: 0.2;
        }
    }
</style>
