import axios from 'axios';
import { joinPaths } from '@/utils/url_utility';

export default class MadisonMetroService {
    constructor(apiKey = '', endpoint = '') {
        const defaultEndpoint = 'https://api.smsmybus.com/v1';

        this.endpoint = (endpoint == '') ? defaultEndpoint : endpoint;
        this.apiKey = apiKey;

    }

    /**
     * Return real-time arrival estimates for the next N buses at a specified stop.
     * @param {String} stopId A four-digit value describing a valid stop in the Metro system.
     * @returns {Promise} axios Promise with parsed data
     */
    getArrivals(stopId) {
        const url = joinPaths(this.endpoint, 'getarrivals');
        const apiKey = this.apiKey;

        return axios({
            url,
            params: {
                key: apiKey,
                stopID: stopId
            }
        });

    }

    /**
     * Return the geo location for a specific stop. 
     * @param {String} stopId four-digit value describing a valid stop in the Metro system.
     * @returns {Promise} axios Promise with parsed data
     */
    getStop(stopId) {
        const url = joinPaths(this.endpoint, 'getstoplocation');
        const apiKey = this.apiKey;
        return axios({
            url,
            params: {
                key: apiKey,
                stopID: stopId
            }
        });
    }

    /**
     * Extract the route direction from the stop's intersection
     * @param {string} intersection 
     * @returns {string} NB/SB/EB/WB
     */
    extractRouteDirection(intersection) {
        const endParenthesisRegex = /\((.*)?\)$/;
        if (endParenthesisRegex.test(intersection)) {
            return intersection.match(endParenthesisRegex)[1];
        }

        return '';
    }

    /**
     * returns a friendly name for a route direction
     * @param {string} routeDirection NB/SB/EB/WB
     * @returns {string} Northbound/Southbound/Eastbound/Westbound
     */
    routeDirectionFriendlyName(routeDirection) {
        switch (routeDirection) {
            case 'NB':
                return 'Northbound';
            case 'SB':
                return 'Southbound';
            case 'EB':
                return 'Eastbound';
            case 'WB':
                return 'Westbound';
            default:
                return ''
        }
    }
}
